import country from "./country";
import contacts from "./contacts";
import contragents from "./contragents";
import contragents_departments from "./contragents_departments";
import brigades from "./brigades";
import brigades_list from "./brigades_list";
import call_log from "./call_log";
import resuscitations from "./resuscitations";
import psych_cares from "./psych_cares";
import protocol_onmk from "./protocol_onmk";
import protocol_slr from "./protocol_slr";
import employees from "./employees";
import files from "./files";
import files_versions from "./files_versions";
import patients from "./patients";
import templates from "./templates";
import icd10 from "./icd10";

export default {
	country,
	brigades,
	contacts,
	contragents,
	'contragents.departments': contragents_departments,
	brigades_list,
	call_log,
	employees,
	templates,
	files,
	'files.versions': files_versions,
	icd10,
	patients,
	resuscitations,
	psych_cares,
	protocol_onmk,
	protocol_slr
}
